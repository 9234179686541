export enum FormStep {
  Basic = "Basic Details",
  Nid = "NID",
  Bank = "Bank Account",
  Address = "Address",
  Education = "Education Details",
  Employer = "Employer Details",
}

export type FormStatus = Record<FormStep, boolean>;

export const INITIAL_FORM_STATUS: FormStatus = {
  [FormStep.Basic]: false,
  [FormStep.Nid]: false,
  [FormStep.Bank]: false,
  [FormStep.Address]: false,
  [FormStep.Education]: false,
  [FormStep.Employer]: false,
};

export enum GenderValues {
  Male = "male",
  Female = "female",
}

export enum MaritalStatus {
  Married = "married",
  Unmarried = "unmarried",
}

export interface BasicDetailsData {
  firstName: string;
  middleName?: string;
  lastName: string;
  gender: GenderValues;
  dob: string;
  fatherName: string;
  motherName: string;
  maritalStatus: MaritalStatus;
  alternatePhone: string;
}

export const INITIAL_BASIC_DETAILS: BasicDetailsData = {
  dob: "",
  alternatePhone: "",
  fatherName: "",
  gender: GenderValues.Male,
  maritalStatus: MaritalStatus.Unmarried,
  firstName: "",
  lastName: "",
  motherName: "",
};

export interface NidDetails {
  photo: string;
  aadhar: string;
  aadharNo: string;
  pan?: string;
  panNo?: string;
  passport?: string;
  passportNo?: string;
}

export const INITIAL_NID_DETAILS: NidDetails = {
  photo: "",
  aadhar: "",
  aadharNo: "",
};

export interface AddressDetails {
  inIndia: boolean;
  houseNo: string;
  locality: string;
  residencyName: string;
  city: string;
  state: string;
  pincode: string;
  landmark: string;
  residingFrom: string;
  residencyProof: string;
  isCurrent: boolean;
}

export const INITIAL_ADDRESS_DETAILS: AddressDetails = {
  inIndia: true,
  houseNo: "",
  locality: "",
  residencyName: "",
  city: "",
  state: "",
  pincode: "",
  landmark: "",
  residingFrom: "",
  residencyProof: "",
  isCurrent: true,
};

export interface EducationDetails {
  university: string;
  degree: string;
  course: string;
  idNumber: string;
  grade: string;
  college: string;
  country: string;
  state: string;
  city: string;
  markSheet: string;
  certificate: string;
}

export const INITIAL_EDUCATION_DETAILS: EducationDetails = {
  university: "",
  degree: "",
  course: "",
  idNumber: "",
  grade: "",
  college: "",
  country: "",
  state: "",
  city: "",
  markSheet: "",
  certificate: "",
};

export interface BankAccountDetails {
  accountNo: string;
  ifsc: string;
}

export const INITIAL_BANK_ACCOUNT_DETAILS: BankAccountDetails = {
  accountNo: "",
  ifsc: "",
};

export interface EmployerDetails {
  company: string;
  designation: string;
  city: string;
  phone: string;
  email: string;
  address: string;
  employeeType: string;
  department: string;
  startsFrom: string;
  endsAt?: string;
  currentlyWorking: boolean;
  manager: {
    firstName: string;
    middleName?: string;
    lastName: string;
    phone: string;
    email: string;
    department: string;
    employeeCode: string;
  };
  salary: number;
  employeeCode: string;
  band: string;
  remark: string;
}

export const INITIAL_EMPLOYER_DETAILS: EmployerDetails = {
  company: "",
  designation: "",
  city: "",
  phone: "",
  email: "",
  address: "",
  employeeType: "",
  department: "",
  startsFrom: "",
  currentlyWorking: true,
  salary: 0,
  employeeCode: "",
  band: "",
  remark: "",
  manager: {
    firstName: "",
    department: "",
    email: "",
    employeeCode: "",
    lastName: "",
    phone: "",
  },
};

interface AdditionalDocsModel {
  [docName: string]: {
    inputLabel: string;
    inputPlaceholder: string;
    inputKey: string;
    uploadLabel: string;
    uploadKey: string;
  };
}

export const ADDITIONAL_DOCS: AdditionalDocsModel = {
  "Pan Card": {
    inputLabel: "PAN Number",
    inputPlaceholder: "Enter PAN number",
    inputKey: "panNo",
    uploadLabel: "Upload PAN",
    uploadKey: "pan",
  },
  Passport: {
    inputLabel: "Passport Number",
    inputPlaceholder: "Enter Passport Number",
    inputKey: "passportNo",
    uploadLabel: "Upload Passport",
    uploadKey: "passport",
  },
};
