import { createIcon } from "utils/icons";

export const ShowIcon = ({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) =>
  createIcon(
    <>
      <title>ionicons-v5-i</title>
      <path
        d="M255.66,112c-77.94,0-157.89,45.11-220.83,135.33a16,16,0,0,0-.27,17.77C82.92,340.8,161.8,400,255.66,400,348.5,400,429,340.62,477.45,264.75a16.14,16.14,0,0,0,0-17.47C428.89,172.28,347.8,112,255.66,112Z"
        style={{
          fill: "none",
          stroke: "#000000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "32px",
        }}
      />
      <circle
        cx="256"
        cy="256"
        r="80"
        style={{
          fill: "none",
          stroke: "#000000",
          strokeMiterlimit: 10,
          strokeWidth: "32px",
        }}
      />
    </>,
    { width: 512, height: 512, className, onClick },
  );

export const HideIcon = ({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) =>
  createIcon(
    <>
      <path
        d="M2 2L22 22"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>,
    { width: 24, height: 24, className, onClick },
  );
