import { useState } from "react";

import { candidateNotJoining, userLogin } from "apis/serverRoutes";
import Button, { ButtonType } from "components/molecules/Button";
import Input, { InputValidation } from "components/molecules/Input";
import Modal from "components/molecules/Modal";
import { LoginData } from "components/LoginPage/types";
import { onLoginSuccess } from "utils/auth";

import styles from "./index.module.scss";

interface FormProps {
  className: string;
  loginData: LoginData;
  getLoginDetails: () => void;
}

const Form = ({ className, loginData, getLoginDetails }: FormProps) => {
  const [email, setEmail] = useState<string>("");
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [notJoiningLoading, setNotJoiningLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [openNotJoiningModal, setOpenNotJoiningModal] =
    useState<boolean>(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);

  const onLoginClick = () => {
    if (isCtaDisabled) return;
    setLoginLoading(true);
    userLogin({
      email: email,
      id: loginData.id,
    })
      .then((res: any) => {
        if (res.data) onLoginSuccess(res.data);
        else setError(res?.response?.data?.message || "Something went wrong!!");
      })
      .catch((err) => console.error(err))
      .finally(() => setLoginLoading(false));
  };

  const onNotJoiningClick = () => {
    if (!loginData.id) return;
    setNotJoiningLoading(true);
    candidateNotJoining(loginData.id)
      .then((res) => {
        if (res.data) {
          setNotJoiningLoading(false);
          setOpenNotJoiningModal(false);
          getLoginDetails();
        } else console.error("Something went wrong");
      })
      .catch((err) => console.error(err));
  };

  const isCtaDisabled = !email || !!error || isEmailInvalid || !loginData.id;

  return (
    <div className={className}>
      <Modal
        open={openNotJoiningModal}
        onClose={() => setOpenNotJoiningModal(false)}
        classNames={{
          modal: styles["modal"],
        }}
        showCloseIcon={false}
      >
        <div className={styles["modal-title"]}>
          Are you sure you do not wish to join?
        </div>
        <div className={styles["modal-cta-holder"]}>
          <Button
            className={styles["modal-cta"]}
            onClick={() => setOpenNotJoiningModal(false)}
          >
            No
          </Button>
          <Button
            buttonType={ButtonType.Outlined}
            className={styles["modal-cta"]}
            loading={notJoiningLoading}
            onClick={onNotJoiningClick}
          >
            Yes
          </Button>
        </div>
      </Modal>
      <div className={styles["greeting"]}>Hello,</div>
      <div className={styles["title"]}>{loginData.name}</div>
      <div className={styles["subtitle"]}>{loginData.message}</div>
      <Input
        className={styles["input"]}
        label={"Email"}
        placeholder={"eg. xyz@gamil.com"}
        value={email}
        onChange={(ev) => {
          setError("");
          setEmail(ev?.target?.value || "");
        }}
        validation={InputValidation.Email}
        setIsInvalidValue={setIsEmailInvalid}
      />
      <Button
        className={styles["login-button"]}
        disabled={isCtaDisabled}
        onClick={onLoginClick}
        loading={loginLoading}
      >
        Let's Start
      </Button>
      <div
        className={styles["not-joining-cta"]}
        onClick={() => setOpenNotJoiningModal(true)}
      >
        I do not wish to join
      </div>
      <div className={styles["error-text"]}>{error}</div>
    </div>
  );
};

export default Form;
