import Input, { InputValidation } from "components/molecules/Input";

import Select from "components/molecules/Select";
import DateInput from "components/molecules/DateInput";

import styles from "./index.module.scss";
import { BasicDetailsData, GenderValues, MaritalStatus } from "../types";

interface BasicDetailsProps {
  basicDetails: BasicDetailsData;
  setBasicDetails: (data: BasicDetailsData) => void;
}

const BasicDetails = ({ setBasicDetails, basicDetails }: BasicDetailsProps) => (
  <>
    <div className={styles["input-container"]}>
      <Input
        className={styles["input"]}
        label={"First Name"}
        value={basicDetails.firstName}
        onChange={(ev) =>
          setBasicDetails({ ...basicDetails, firstName: ev.target.value })
        }
        placeholder={"Enter First Name"}
      />
      <Input
        className={styles["input"]}
        label={"Middle Name"}
        value={basicDetails.middleName}
        onChange={(ev) =>
          setBasicDetails({ ...basicDetails, middleName: ev.target.value })
        }
        placeholder={"Enter Middle Name"}
      />
      <Input
        className={styles["input"]}
        label={"Last Name"}
        value={basicDetails.lastName}
        onChange={(ev) =>
          setBasicDetails({ ...basicDetails, lastName: ev.target.value })
        }
        placeholder={"Enter Last Name"}
      />
      <Select
        value={basicDetails.gender}
        className={styles["input"]}
        options={Object.entries(GenderValues).map(([name, value]) => ({
          value,
          name,
        }))}
        onOptionSelect={(val) =>
          setBasicDetails({
            ...basicDetails,
            gender: val as GenderValues,
          })
        }
        label={"Gender"}
      />
      <DateInput
        label={"DOB"}
        selectedDate={basicDetails.dob}
        onSelectedDateChange={(date) =>
          setBasicDetails({ ...basicDetails, dob: date })
        }
        containerClassName={styles["input"]}
        placeholderText={"Enter DOB"}
      />
      <Input
        className={styles["input"]}
        label={"Alternate Mobile number"}
        value={basicDetails.alternatePhone}
        onChange={(ev) =>
          setBasicDetails({
            ...basicDetails,
            alternatePhone: ev.target.value,
          })
        }
        placeholder={"Enter Alternate Mobile Number"}
        validation={InputValidation.Phone}
      />
      <Input
        className={styles["input"]}
        label={"Father's Name"}
        value={basicDetails.fatherName}
        onChange={(ev) =>
          setBasicDetails({ ...basicDetails, fatherName: ev.target.value })
        }
        placeholder={"Enter First Name"}
      />
      <Input
        className={styles["input"]}
        label={"Mother's Name"}
        value={basicDetails.motherName}
        onChange={(ev) =>
          setBasicDetails({ ...basicDetails, motherName: ev.target.value })
        }
        placeholder={"Enter Mother's Name"}
      />
      <Select
        value={basicDetails.maritalStatus}
        className={styles["input"]}
        options={Object.entries(MaritalStatus).map(([name, value]) => ({
          value,
          name,
        }))}
        onOptionSelect={(val) =>
          setBasicDetails({
            ...basicDetails,
            maritalStatus: val as MaritalStatus,
          })
        }
        label={"Marital Status"}
      />
    </div>
  </>
);

export default BasicDetails;
