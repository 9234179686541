import Input from "components/molecules/Input";

import { BankAccountDetails } from "../types";
import styles from "./index.module.scss";

interface BankAccountDetailsProps {
  bankAccountDetails: BankAccountDetails;
  setBankAccountDetails: (val: BankAccountDetails) => void;
}

const BankAccount = ({
  bankAccountDetails,
  setBankAccountDetails,
}: BankAccountDetailsProps) => (
  <div className={styles["input-container"]}>
    <Input
      className={styles["input"]}
      label={"Bank Account Number"}
      value={bankAccountDetails.accountNo}
      onChange={(ev) =>
        setBankAccountDetails({
          ...bankAccountDetails,
          accountNo: ev.target.value,
        })
      }
      placeholder={"Enter Bank Account Number"}
    />
    <Input
      className={styles["input"]}
      label={"IFSC Code"}
      value={bankAccountDetails.ifsc}
      onChange={(ev) =>
        setBankAccountDetails({
          ...bankAccountDetails,
          ifsc: ev.target.value?.toUpperCase(),
        })
      }
      placeholder={"Enter IFSC Code"}
    />
  </div>
);

export default BankAccount;
