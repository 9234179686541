import {
  InputHTMLAttributes,
  ReactNode,
  HTMLInputTypeAttribute,
  useEffect,
  useState,
} from "react";
import classnames from "classnames";

import { EmailValidation, PhoneValidation } from "utils/validations";

import styles from "./index.module.scss";
import { HideIcon, ShowIcon } from "./icons";

export enum InputValidation {
  Email,
  Phone,
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string | ReactNode;
  labelClassName?: string;
  validation?: InputValidation;
  setIsInvalidValue?: (isInvalid: boolean) => void;
}

const Input = ({
  className,
  labelClassName,
  label,
  type,
  validation,
  setIsInvalidValue,
  ...props
}: InputProps) => {
  const [inputType, setInputType] = useState<
    HTMLInputTypeAttribute | undefined
  >(type);

  const getValidationError = (): string => {
    if (!props.value) return "";
    const stringValue = props.value.toString();
    if (validation === InputValidation.Email)
      return EmailValidation(stringValue);
    if (validation === InputValidation.Phone)
      return PhoneValidation(stringValue);
    return "";
  };

  const validationError = getValidationError();
  const isInValidValue = !!validationError;

  useEffect(() => {
    if (setIsInvalidValue) setIsInvalidValue(isInValidValue);
  }, [isInValidValue]);

  return (
    <div className={className}>
      {label && (
        <div className={classnames(styles["label"], labelClassName)}>
          {label}
        </div>
      )}
      <div className={styles["input-container"]}>
        <input
          className={
            styles[
              type === "password"
                ? "input-password"
                : isInValidValue
                  ? "input-error"
                  : "input"
            ]
          }
          type={inputType}
          {...props}
        />
        {type === "password" && (
          <>
            {inputType === "password" ? (
              <ShowIcon
                className={styles["show-icon"]}
                onClick={() => setInputType("text")}
              />
            ) : (
              <HideIcon
                className={styles["show-icon"]}
                onClick={() => setInputType("password")}
              />
            )}
          </>
        )}
        <div className={styles["validation-error"]}>{validationError}</div>
      </div>
    </div>
  );
};

export default Input;
