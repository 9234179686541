import Input from "components/molecules/Input";
import Button from "components/molecules/Button";

import styles from "./index.module.scss";
import { EducationDetails, INITIAL_EDUCATION_DETAILS } from "../types";
import PhotoUploader from "../PhotoUploader";

interface EducationProps {
  educationDetails: EducationDetails[];
  setEducationDetails: (val: EducationDetails[]) => void;
}

const Education = ({
  setEducationDetails,
  educationDetails,
}: EducationProps) => (
  <>
    {educationDetails.map((education, idx) => (
      <>
        {idx !== 0 ? (
          <Button
            className={styles["remove-cta"]}
            onClick={() =>
              setEducationDetails([
                ...educationDetails.slice(0, idx),
                ...educationDetails.slice(idx + 1, educationDetails.length),
              ])
            }
          >
            Remove
          </Button>
        ) : (
          <div className={styles["highest-qualification-tag"]}>
            Highest Qualification
          </div>
        )}
        <div className={styles["input-container"]}>
          <Input
            className={styles["input"]}
            label={"University/Board name"}
            value={education.university}
            onChange={(ev) => {
              const educationList = [...educationDetails];
              educationList[idx].university = ev.target.value;
              setEducationDetails(educationList);
            }}
            placeholder={"Enter University Name"}
          />
          <Input
            className={styles["input"]}
            label={"Qualification Degree"}
            value={education.degree}
            onChange={(ev) => {
              const educationList = [...educationDetails];
              educationList[idx].degree = ev.target.value;
              setEducationDetails(educationList);
            }}
            placeholder={"Enter Qualification Degree"}
          />
          <Input
            className={styles["input"]}
            label={"Course Name"}
            value={education.course}
            onChange={(ev) => {
              const educationList = [...educationDetails];
              educationList[idx].course = ev.target.value;
              setEducationDetails(educationList);
            }}
            placeholder={"Enter Course Name"}
          />
          <Input
            className={styles["input"]}
            label={"ID Number"}
            value={education.idNumber}
            onChange={(ev) => {
              const educationList = [...educationDetails];
              educationList[idx].idNumber = ev.target.value;
              setEducationDetails(educationList);
            }}
            placeholder={"Enter ID Number"}
          />
          <Input
            className={styles["input"]}
            label={"Grade"}
            value={education.grade}
            onChange={(ev) => {
              const educationList = [...educationDetails];
              educationList[idx].grade = ev.target.value;
              setEducationDetails(educationList);
            }}
            placeholder={"Enter Grade"}
          />
          <Input
            className={styles["input"]}
            label={"Collage / Institute / School name"}
            value={education.college}
            onChange={(ev) => {
              const educationList = [...educationDetails];
              educationList[idx].college = ev.target.value;
              setEducationDetails(educationList);
            }}
            placeholder={"Enter School"}
          />
          <Input
            className={styles["input"]}
            label={"Select Country"}
            value={education.country}
            onChange={(ev) => {
              const educationList = [...educationDetails];
              educationList[idx].country = ev.target.value;
              setEducationDetails(educationList);
            }}
            placeholder={"Enter Country Name"}
          />
          <Input
            className={styles["input"]}
            label={"Select State"}
            value={education.state}
            onChange={(ev) => {
              const educationList = [...educationDetails];
              educationList[idx].state = ev.target.value;
              setEducationDetails(educationList);
            }}
            placeholder={"Enter State Name"}
          />
          <Input
            className={styles["input"]}
            label={"Select City"}
            value={education.city}
            onChange={(ev) => {
              const educationList = [...educationDetails];
              educationList[idx].city = ev.target.value;
              setEducationDetails(educationList);
            }}
            placeholder={"Enter City"}
          />
        </div>
        <div className={styles["uploader-container"]}>
          <div className={styles["uploader-holder"]}>
            <div className={styles["label"]}>Upload Mark sheet</div>
            <PhotoUploader
              className={styles["uploader"]}
              image={education.markSheet}
              onImageChange={(markSheet) => {
                const educationList = [...educationDetails];
                educationList[idx].markSheet = markSheet;
                setEducationDetails(educationList);
              }}
            />
          </div>
          <div className={styles["uploader-holder"]}>
            <div className={styles["label"]}>Upload Degree Certificate</div>
            <PhotoUploader
              className={styles["uploader"]}
              image={education.certificate}
              onImageChange={(degree) => {
                const educationList = [...educationDetails];
                educationList[idx].certificate = degree;
                setEducationDetails(educationList);
              }}
            />
          </div>
        </div>
      </>
    ))}
    <Button
      className={styles["add-education-cta"]}
      onClick={() => {
        setEducationDetails([
          ...educationDetails,
          { ...INITIAL_EDUCATION_DETAILS },
        ]);
      }}
    >
      + Add Education
    </Button>
  </>
);

export default Education;
