import { useState } from "react";

import { sendAadhaarOtp, verifyAadhaarOtp } from "apis/serverRoutes";
import Button from "components/molecules/Button";
import Input from "components/molecules/Input";
import Select from "components/molecules/Select";
import { DEFAULT_ERROR } from "utils/constants";

import styles from "./index.module.scss";
import { ADDITIONAL_DOCS, NidDetails } from "../types";
import PhotoUploader from "../PhotoUploader";
import { CompletedIcon } from "../icons";
import { EditIcon } from "./icons";

interface AadhaarOtpData {
  aadhaar: string;
  referenceId: string;
  status: number;
  message: string;
}

interface NidProps {
  nidDetails: NidDetails;
  setNidDetails: (val: NidDetails) => void;
  selectedAdditionalDocuments: string[];
  setSelectedAdditionalDocuments: (docs: string[]) => void;
}

const Nid = ({
  setNidDetails,
  nidDetails,
  setSelectedAdditionalDocuments,
  selectedAdditionalDocuments,
}: NidProps) => {
  const [aadhaarOtpData, setAadhaarOtpData] = useState<AadhaarOtpData | null>(
    null,
  );
  const [aadhaarNo, setAadhaarNo] = useState<string>("");
  const [aadhaarOtp, setAadhaarOtp] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [aadhaarError, setAadhaarStatus] = useState<string>("");

  const restAdditionalDocuments = Object.keys(ADDITIONAL_DOCS).filter(
    (e) => !selectedAdditionalDocuments.includes(e),
  );

  const onAadhaarSendOtpClick = () => {
    if (!aadhaarNo) return;
    setLoader(true);
    sendAadhaarOtp(aadhaarNo)
      .then((res: any) => {
        if (res.data) {
          setAadhaarOtpData(res.data);
          setAadhaarStatus("OTP sent to registered mobile");
        } else setAadhaarStatus(res?.response?.data?.message || DEFAULT_ERROR);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoader(false));
  };

  const onAadhaarVerifyOtpClick = () => {
    if (!aadhaarOtp || !aadhaarOtpData) return;
    setLoader(true);
    verifyAadhaarOtp({
      otp: aadhaarOtp,
      aadharNo: aadhaarOtpData.aadhaar,
      referenceId: aadhaarOtpData.referenceId,
    })
      .then((res: any) => {
        if (res.data) setNidDetails({ ...nidDetails, aadharNo: aadhaarNo });
        else setAadhaarStatus(res?.response?.data?.message || DEFAULT_ERROR);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoader(false));
  };

  const removeAdditionalDoc = (key: string) => {
    const doc = ADDITIONAL_DOCS[key];
    if (doc) {
      const tempDetails = { ...nidDetails };
      //@ts-ignore
      delete tempDetails[doc.uploadKey];
      //@ts-ignore
      delete tempDetails[doc.inputKey];
      setNidDetails({ ...tempDetails });
    }
    const keyIdx = selectedAdditionalDocuments.indexOf(key);
    const tempArr = [...selectedAdditionalDocuments];
    if (keyIdx > -1) {
      tempArr.splice(keyIdx, 1);
      setSelectedAdditionalDocuments([...tempArr]);
    }
  };

  const onAdditionalDocSelect = (val: string) => {
    const doc = ADDITIONAL_DOCS[val];
    if (doc) {
      const tempDetails = { ...nidDetails };
      //@ts-ignore
      tempDetails[doc.uploadKey] = "";
      //@ts-ignore
      tempDetails[doc.inputKey] = "";

      setNidDetails({ ...tempDetails });
    }
    setSelectedAdditionalDocuments([...selectedAdditionalDocuments, val]);
  };

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["uploader-holder"]}>
          <div className={styles["label"]}>Upload Selfie</div>
          <PhotoUploader
            className={styles["uploader"]}
            image={nidDetails.photo}
            onImageChange={(photo) => setNidDetails({ ...nidDetails, photo })}
          />
        </div>
        <div className={styles["uploader-holder"]}>
          <div className={styles["label"]}>Upload Aadhaar</div>
          <PhotoUploader
            className={styles["uploader"]}
            image={nidDetails.aadhar}
            onImageChange={(aadhar) => setNidDetails({ ...nidDetails, aadhar })}
          />
        </div>
      </div>
      <div className={styles["input-container"]}>
        <div className={styles["input"]}>
          <Input
            label={
              <>
                Aadhaar Number
                {aadhaarOtpData && (
                  <EditIcon
                    className={styles["edit-icon"]}
                    onClick={() => {
                      setAadhaarOtpData(null);
                      setAadhaarStatus("");
                    }}
                  />
                )}
              </>
            }
            value={nidDetails.aadharNo || aadhaarNo}
            onChange={(ev) => {
              setAadhaarStatus("");
              setAadhaarNo(ev.target.value);
            }}
            placeholder={"Enter Aadhaar number"}
            disabled={!!aadhaarOtpData}
          />
          {!nidDetails.aadharNo && (
            <>
              {aadhaarOtpData ? (
                <>
                  <Input
                    label={
                      <>
                        Enter OTP
                        <div
                          className={styles["resend-cta"]}
                          onClick={() => {
                            setAadhaarStatus("");
                            setAadhaarOtpData(null);
                            onAadhaarSendOtpClick();
                          }}
                        >
                          Resend
                        </div>
                      </>
                    }
                    value={aadhaarOtp}
                    onChange={(ev) => setAadhaarOtp(ev.target.value)}
                    placeholder={"Enter Aadhaar OTP"}
                    className={styles["otp-input"]}
                  />
                  <Button
                    disabled={!aadhaarOtp}
                    onClick={onAadhaarVerifyOtpClick}
                    loading={loader}
                    className={styles["otp-cta"]}
                  >
                    Verify
                  </Button>
                </>
              ) : (
                <Button
                  disabled={!aadhaarNo}
                  onClick={onAadhaarSendOtpClick}
                  loading={loader}
                  className={styles["otp-cta"]}
                >
                  Send OTP
                </Button>
              )}
            </>
          )}
          {!!nidDetails.aadharNo ? (
            <div className={styles["verified-text"]}>
              <CompletedIcon className={styles["completed-icon"]} />
              Verified
            </div>
          ) : (
            !!aadhaarError && (
              <div className={styles["error-text"]}>{aadhaarError}</div>
            )
          )}
        </div>
      </div>
      <Select
        options={restAdditionalDocuments.map((val) => ({
          value: val,
          name: val,
        }))}
        onOptionSelect={onAdditionalDocSelect}
        label={"Additional Documents"}
        className={styles["select-additional"]}
        value={""}
      />
      {selectedAdditionalDocuments.map((docName) => {
        const doc = ADDITIONAL_DOCS[docName];
        return (
          <div className={styles["additional-doc-holder"]} key={docName}>
            <Button
              className={styles["remove-cta"]}
              onClick={() => removeAdditionalDoc(docName)}
            >
              - Remove
            </Button>
            <Input
              className={styles["input"]}
              label={doc.inputLabel}
              //@ts-ignore
              value={nidDetails[doc.inputKey]}
              onChange={(ev) =>
                setNidDetails({
                  ...nidDetails,
                  [doc.inputKey]: ev.target.value?.toUpperCase(),
                })
              }
              placeholder={doc.inputPlaceholder}
            />
            <div className={styles["uploader-holder"]}>
              <div className={styles["label"]}>{doc.uploadLabel}</div>
              <PhotoUploader
                className={styles["uploader"]}
                //@ts-ignore
                image={nidDetails[doc.uploadKey] || ""}
                onImageChange={(pan) =>
                  setNidDetails({ ...nidDetails, [doc.uploadKey]: pan })
                }
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Nid;
