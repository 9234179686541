import classnames from "classnames";

import styles from "./index.module.scss";
import { BottomRightIcon, TopLeftIcon } from "./icons";
import { LoginData } from "../types";

const Banner = ({
  className,
  loginData,
}: {
  className: string;
  loginData: LoginData;
}) => (
  <div className={classnames(styles["container"], className)}>
    <TopLeftIcon className={styles["top-left-icon"]} />
    <BottomRightIcon className={styles["bottom-right-icon"]} />
    <div className={styles["title"]}>{loginData.companyName}</div>
    <div className={styles["subtitle"]}>
      Welcome to the onboarding process at {loginData.companyName}. InfinitiAI,
      our trusted BGV partner, manages the entire onboarding experience.
    </div>
    <ul className={styles["list"]}>
      <li className={styles["list-item"]}>
        Login with registered email ID to upload your details.
      </li>
      <li className={styles["list-item"]}>
        We will share your details with {loginData.companyName}.
      </li>
      <li className={styles["list-item"]}>
        Be assured, your data is safe with us.
      </li>
    </ul>
  </div>
);

export default Banner;
