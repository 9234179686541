import Input from "components/molecules/Input";
import DateInput from "components/molecules/DateInput";

import styles from "./index.module.scss";
import { AddressDetails, INITIAL_ADDRESS_DETAILS } from "../types";
import PhotoUploader from "../PhotoUploader";

interface AddressProps {
  addressDetails: AddressDetails[];
  setAddressDetails: (val: AddressDetails[]) => void;
}

const Address = ({ setAddressDetails, addressDetails }: AddressProps) => (
  <>
    {addressDetails.map((address, idx) => (
      <>
        {idx === 0 ? (
          <>
            <div className={styles["address-label"]}>Permanent Address</div>
            <div className={styles["radio-holder"]}>
              <div className={styles["label"]}>
                Is this your Current Address?
              </div>
              <Input
                type={"radio"}
                label={"Yes"}
                checked={address.isCurrent}
                className={styles["radio"]}
                labelClassName={styles["radio-label"]}
                onClick={() => {
                  const addressList = [addressDetails[0]];
                  addressList[idx].isCurrent = true;
                  setAddressDetails(addressList);
                }}
              />
              <Input
                type={"radio"}
                label={"No"}
                checked={!address.isCurrent}
                className={styles["radio"]}
                labelClassName={styles["radio-label"]}
                onClick={() => {
                  const addressList = [
                    addressDetails[0],
                    { ...INITIAL_ADDRESS_DETAILS },
                  ];
                  addressList[idx].isCurrent = false;
                  setAddressDetails(addressList);
                }}
              />
            </div>
          </>
        ) : (
          <div className={styles["current-address-label"]}>Current Address</div>
        )}
        <div className={styles["radio-holder"]}>
          <div className={styles["label"]}>Is this is address in India?</div>
          <Input
            type={"radio"}
            label={"Yes"}
            checked={address.inIndia}
            className={styles["radio"]}
            labelClassName={styles["radio-label"]}
            onClick={() => {
              const addressList = [...addressDetails];
              addressList[idx].inIndia = true;
              setAddressDetails(addressList);
            }}
          />
          <Input
            type={"radio"}
            label={"No"}
            checked={!address.inIndia}
            className={styles["radio"]}
            labelClassName={styles["radio-label"]}
            onClick={() => {
              const addressList = [...addressDetails];
              addressList[idx].inIndia = false;
              setAddressDetails(addressList);
            }}
          />
        </div>
        <div className={styles["input-container"]}>
          <Input
            className={styles["input"]}
            label={"Flat/ House No/ Floor/ Building No"}
            value={address.houseNo}
            onChange={(ev) => {
              const addressList = [...addressDetails];
              addressList[idx].houseNo = ev.target.value;
              setAddressDetails(addressList);
            }}
            placeholder={"Enter House Number"}
          />
          <Input
            className={styles["input"]}
            label={"Colony/ Street/ Locality"}
            value={address.locality}
            onChange={(ev) => {
              const addressList = [...addressDetails];
              addressList[idx].locality = ev.target.value;
              setAddressDetails(addressList);
            }}
            placeholder={"Enter Locality"}
          />
          <Input
            className={styles["input"]}
            label={"Residency name"}
            value={address.residencyName}
            onChange={(ev) => {
              const addressList = [...addressDetails];
              addressList[idx].residencyName = ev.target.value;
              setAddressDetails(addressList);
            }}
            placeholder={"Enter Residency name"}
          />
          <Input
            className={styles["input"]}
            label={"City"}
            value={address.city}
            onChange={(ev) => {
              const addressList = [...addressDetails];
              addressList[idx].city = ev.target.value;
              setAddressDetails(addressList);
            }}
            placeholder={"Enter City"}
          />
          <Input
            className={styles["input"]}
            label={"State"}
            value={address.state}
            onChange={(ev) => {
              const addressList = [...addressDetails];
              addressList[idx].state = ev.target.value;
              setAddressDetails(addressList);
            }}
            placeholder={"Enter State"}
          />
          <Input
            className={styles["input"]}
            label={"Pin code"}
            value={address.pincode}
            onChange={(ev) => {
              const addressList = [...addressDetails];
              addressList[idx].pincode = ev.target.value;
              setAddressDetails(addressList);
            }}
            placeholder={"Enter Pin Code"}
          />
          <Input
            className={styles["input"]}
            label={"Landmark"}
            value={address.landmark}
            onChange={(ev) => {
              const addressList = [...addressDetails];
              addressList[idx].landmark = ev.target.value;
              setAddressDetails(addressList);
            }}
            placeholder={"Enter Landmark"}
          />
          <DateInput
            label={"Residing from"}
            selectedDate={address.residingFrom}
            onSelectedDateChange={(date) => {
              const addressList = [...addressDetails];
              addressList[idx].residingFrom = date;
              setAddressDetails(addressList);
            }}
            containerClassName={styles["input"]}
            placeholderText={"Enter Residing from date"}
          />
        </div>
        <div className={styles["uploader-holder"]}>
          <div className={styles["label"]}>Upload Address Proof</div>
          <PhotoUploader
            className={styles["uploader"]}
            image={address.residencyProof}
            onImageChange={(residencyProof) => {
              const addressList = [...addressDetails];
              addressList[idx].residencyProof = residencyProof;
              setAddressDetails(addressList);
            }}
          />
        </div>
      </>
    ))}
  </>
);

export default Address;
